<template>
  <div
    ref="wrapper"
    class="scroll-bar"
    @touchstart="onStart"
    @touchmove.prevent="onMove"
    @touchend="onEnd"
    @transitionend="onTransitionEnd"
  >
    <div
      ref="scroller"
      class="scroll-bar__list"
      :style="scrollerStyle"
    >
      <!-- ScrollBarItem -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'ScrollBar',
  emits: ['checkEnd'],
  props: {
    // ar翻转
    reverse: Boolean,
    // 当前选中值
    modelValue: { 
      type: Number,
      default: 0,
    },
    // 左边露出的滑块数量
    leftShowNum: {
      type: Number,
      default: 1
    },
    // 右边露出的滑块数量
    rightShowNum: {
      type: Number,
      default: 2
    },
    rightGap: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      wrapper: null,                // 外层容器
      scroller: null,               // 滚动容器
      minX: 0,                      // 最小偏移值
      maxX: 0,                      // 最大偏移值
      wrapperWidth: 0,              // 容器宽度
      offsetX: 0,                   // transLate值
      duration: 0,                  // 动画过渡时间
      bezier: 'linear',             // 动画
      startX: 0,                    // touch时，当前的transLate值
      pointX: 0,                    // touch时的坐标
      startTime: 0,                 // 惯性滑动范围内的 startTime
      momentumstartX: 0,            // 惯性滑动范围内的 startX
      momentumTimeThreshold: 300,   // 惯性滑动的启动 时间阈值
      momentumXThreshold: 15,       // 惯性滑动的启动 距离阈值
      isStarted: false,             // start锁
      stopTransitionEnd: false,     // 阻止过渡时的逻辑
      needSetItemToStart: true,     // 是否需要计算贴边
      isEnd: false                  // 是否到底
    }
  },
  computed: {
    scrollerStyle() {
      return {
        'transform': `translate3d(${this.offsetX}px, 0, 0)`,
        'transition-duration': `${this.duration}ms`,
        'transition-timing-function': this.bezier,
      }
    },
    itemWidth () {
      return this.$refs['scroller']?.children[0]?.offsetWidth + this.rightGap
    }
  },
  watch: {
    modelValue () {
      const activeVm = this.$refs['scroller']?.children[this.modelValue]
      const prevVm = this.$refs['scroller']?.children[this.modelValue - 1]
      const nextVm = this.$refs['scroller']?.children[this.modelValue + 1]
      this.needSetItemToStart = false

      if (!activeVm || !prevVm || !nextVm) return // 兼容左右临界点

      const activeItem = activeVm
      const prevItem = prevVm
      const nextItem = nextVm


      const activeItemWidth = activeItem.offsetWidth + this.rightGap
      const prevItemWidth = prevItem.offsetWidth + this.rightGap
      const nextItemWidth = nextItem.offsetWidth + this.rightGap

      const offsetLeft = activeItem.offsetLeft
      const absOffsetX = Math.abs(this.offsetX)
      let changeX = 0
      if (this.reverse) {
        // 左滑
        if (offsetLeft >= (this.maxX - absOffsetX) + (this.wrapperWidth - activeItemWidth * this.leftShowNum + this.rightGap)) {
          changeX = -prevItemWidth
        }
        // 右滑
        if (offsetLeft <  (this.maxX - absOffsetX) + activeItemWidth * this.rightShowNum) {
          changeX = nextItemWidth
        }
      } else {
        // 左滑
        if (offsetLeft >= absOffsetX + (this.wrapperWidth - (activeItemWidth * this.rightShowNum - this.rightGap))) {
          changeX = -prevItemWidth
        }
        
        // 右滑
        if (offsetLeft < absOffsetX + (activeItemWidth * this.leftShowNum)) {
          changeX = nextItemWidth
        } 
      }

      let lastX = changeX + this.offsetX

      // 临界
      if (this.reverse) {
        if (lastX > this.maxX) lastX = this.maxX
        if (lastX < 0) lastX = 0
      } else {
        if (lastX > 0) lastX = 0
        if (lastX < this.minX) lastX = this.minX
      }

      this.offsetX = lastX
      this.duration = 500
      this.bezier = 'cubic-bezier(.165, .84, .44, 1)'
    }
  },
  mounted() {
    nextTick(() => {
      this.wrapper = this.$refs.wrapper
      this.scroller = this.$refs.scroller
      const { width: wrapperWidth } = this.wrapper.getBoundingClientRect()
      const { width: scrollWidth } = this.scroller.getBoundingClientRect()
      this.wrapperWidth = wrapperWidth
      this.scrollWidth = scrollWidth
      if (this.reverse) {
        this.maxX = scrollWidth > wrapperWidth ? scrollWidth - wrapperWidth : 0
      } else {
        this.minX = scrollWidth > wrapperWidth ? wrapperWidth - scrollWidth : 0
      }
    })
  },
  methods: {
    onStart(e) {
      const point = e.touches ? e.touches[0] : e
      this.needSetItemToStart = true
      this.isEnd = false
      this.isStarted = true
      this.duration = 0
      this.stop()
      this.pointX = point.pageX
      this.momentumstartX = this.startX = this.offsetX
      this.startTime = new Date().getTime()
    },
    onMove(e) {
      if (!this.isStarted) return
      const point = e.touches ? e.touches[0] : e
      const deltaX = point.pageX - this.pointX
      // 浮点数坐标会影响渲染速度
      let offsetX = Math.round(this.startX + deltaX)
      // 超出边界时增加阻力
      let isExceed
      if (this.reverse) {
        isExceed = offsetX > this.maxX || offsetX < this.minX
      } else {
        isExceed = offsetX < this.minX || offsetX > this.maxX
      }
      if (isExceed) {
        offsetX = Math.round(this.startX + deltaX / 3)
      }
      this.offsetX = offsetX
      const now = new Date().getTime()
      // 记录在触发惯性滑动条件下的偏移值和时间
      if (now - this.startTime > this.momentumTimeThreshold) {
        this.momentumstartX = this.offsetX
        this.startTime = now
      }
    },
    onEnd() {
      if (!this.isStarted) return
      this.isStarted = false
      this.checkIsEnd()
      if (this.isNeedReset()) return
      const absDeltaX = Math.abs(this.offsetX - this.momentumstartX)
      const duration = new Date().getTime() - this.startTime
      // 启动惯性滑动
      if (duration < this.momentumTimeThreshold && absDeltaX > this.momentumXThreshold) {
        const inertialScroll = this.inertialScroll(this.offsetX, this.momentumstartX, duration)
        this.offsetX = Math.round(inertialScroll.destination)
        this.duration = inertialScroll.duration
        this.bezier = inertialScroll.bezier

        this.triggerMomentum = true

        nextTick(() => {
          this.triggerMomentum = false
        })
      }

      // 非惯性滚动，马上触发计算首位贴边
      if (!this.triggerMomentum) {
        this.setItemToStart()
      }
    },
    onTransitionEnd() {
      if (this.stopTransitionEnd) return this.stopTransitionEnd = false

      this.checkIsEnd()
      if (this.isNeedReset()) return

      if (this.needSetItemToStart) {
        this.setItemToStart()
      }
    },
    inertialScroll(current, start, duration) {
      const durationMap = {
        'noBounce': 2500,
        'weekBounce': 800,
        'strongBounce': 400,
      }
      const bezierMap = {
        'noBounce': 'cubic-bezier(.17, .89, .45, 1)',
        'weekBounce': 'cubic-bezier(.25, .46, .45, .94)',
        'strongBounce': 'cubic-bezier(.25, .46, .45, .94)',
      }
      let type = 'noBounce'
      // 惯性滑动加速度
      const deceleration = 0.003
      // 回弹阻力
      const bounceRate = 10
      // 强弱回弹的分割值
      const bounceThreshold = 300
      // 回弹的最大限度
      const maxOverflowX = this.wrapperWidth / 6
      let overflowX

      const distance = current - start
      const speed = 2 * Math.abs(distance) / duration
      let destination = current + speed / deceleration * (distance < 0 ? -1 : 1)
      // 左滑
      if (destination < this.minX) {
        overflowX = this.minX - destination
        type = overflowX > bounceThreshold ? 'strongBounce' : 'weekBounce'
        destination = Math.max(this.minX - maxOverflowX, this.minX - overflowX / bounceRate)
      } else if (destination > this.maxX) { // 右滑
        overflowX = destination - this.maxX
        type = overflowX > bounceThreshold ? 'strongBounce' : 'weekBounce'
        destination = Math.min(this.maxX + maxOverflowX, this.maxX + overflowX / bounceRate)
      }

      return {
        destination,
        duration: durationMap[type],
        bezier: bezierMap[type],
      }
    },
    checkIsEnd () {
      this.isEnd = false
      const offsetX = this.reverse ? this.maxX : this.minX
      if (this.offsetX == offsetX) {
        this.isEnd = true
      }

      this.$emit('checkEnd', this.isEnd)

      return this.isEnd
    },
    // 超出边界时需要重置位置
    isNeedReset() {
      let offsetX
      
      if (this.offsetX < this.minX) {
        offsetX = this.minX
      } else if (this.offsetX > this.maxX) {
        offsetX = this.maxX
      }

      if (typeof offsetX !== 'undefined') {
        this.offsetX = offsetX
        this.duration = 500
        this.bezier = 'cubic-bezier(.165, .84, .44, 1)'
        return true
      }
      return false
    },
    stop() {
      // 获取当前 translate 的位置
      const matrix = window.getComputedStyle(this.scroller).getPropertyValue('transform')
      this.offsetX = +matrix.split(')')[0].split(', ')[4]
    },
    // 首位贴边
    setItemToStart () {
      if (this.offsetX !== this.startX && this.offsetX !== this.minX && this.offsetX !== this.maxX) {
        let intNum
        const itemRatio = Math.abs(this.offsetX) / this.itemWidth
        const perGroup = Math.ceil(itemRatio)
        const alreadyStart = itemRatio % 1 === 0 // 已经贴边

        if (alreadyStart) return

        this.stopTransitionEnd = true

        if (this.reverse) {
          // 左滑
          if (this.offsetX > this.startX) {
            intNum = perGroup
          } else { // 右滑
            intNum = perGroup - 1
          }
        } else {
          // 左滑
          if (this.offsetX < this.startX) {
            intNum = -perGroup
          } else { // 右滑
            intNum = -perGroup + 1
          }
        }

        if (typeof intNum !== 'undefined') {
          this.offsetX = intNum * this.itemWidth
          this.duration = 500
          this.bezier = 'cubic-bezier(.165, .84, .44, 1)'
        }
      }
    },
    // 向后移动
    scrollToNext (index) {
      let offsetX

      if (this.reverse) {
        offsetX = this.offsetX + index * this.itemWidth
        if (offsetX > this.maxX) {
          offsetX = this.maxX
        }
      } else {
        offsetX = this.offsetX - index * this.itemWidth
        if (offsetX < this.minX) {
          offsetX = this.minX
        }
      }

      if (typeof offsetX !== 'undefined') {
        this.offsetX = offsetX
        this.duration = 500
        this.bezier = 'cubic-bezier(.165, .84, .44, 1)'
      }
    },
  },
})
</script>

<style lang="less">
.scroll-bar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 0;
  &__list {
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }
}

</style>
